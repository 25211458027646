import { useEffect } from 'react';

import { useLazyGetExpertCommissionsQuery } from 'services/expert-commissions/expertCommissionsApiService';
import { GetExpertCommissionsPropsType } from 'services/expert-commissions/expertCommissionsApiService.types';

import { FormResponseType } from './suggestionsCommissions.utils';

interface UseRequestProps {
  requestBody: FormResponseType;
  isDebouncing?: boolean;
}

const useRequest = (props: UseRequestProps) => {
  const { requestBody, isDebouncing } = props;

  const [getCommissions, { data: commissions, isLoading, isFetching, isUninitialized }] =
    useLazyGetExpertCommissionsQuery();

  useEffect(() => {
    if (requestBody) {
      const { page, size, property, order, search } = requestBody ?? {};

      const initialRequestBody: GetExpertCommissionsPropsType = {
        page,
        size,
        search,
      };

      if (property && order) {
        initialRequestBody.sort = [{ order, property }];
      }

      getCommissions(initialRequestBody);
    }
  }, [requestBody, getCommissions]);

  return {
    commissions,
    isLoading: isLoading || isUninitialized,
    isFetching: isDebouncing || isFetching,
  };
};

export default useRequest;
