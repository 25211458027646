import { useState } from 'react';

import Pages from 'pages';
import useInitialAuthRequestsHook from 'utils/useInitialAuthRequestsHook';

import { ConfigProvider } from 'gazprom-ui-lib';

import LoginLoader from 'containers/login-loader';
import SavedModal from 'containers/saved-modal';

import './App.module.scss';
import { SaveModalConfigType, SaveModalContext } from './app.utils';
import useLastUserActionHook from './utils/useLastUserAction.hook';
import usePinCodeAuthorizeHook from './utils/usePinCodeAuthorize.hook';

const AppPrepare = () => {
  const { isLoading, getEmployeesIsLoading } = useInitialAuthRequestsHook();

  useLastUserActionHook();
  usePinCodeAuthorizeHook();

  if (isLoading || getEmployeesIsLoading) return <LoginLoader />;

  return (
    <ConfigProvider.CustomContext.Provider value={{ isMobile: false }}>
      <App />
    </ConfigProvider.CustomContext.Provider>
  );
};

const App = () => {
  const [modalConfig, setModalConfig] = useState<SaveModalConfigType>(null);

  return (
    <SaveModalContext.Provider value={setModalConfig}>
      <Pages />
      <SavedModal modalConfig={modalConfig} setModalConfig={setModalConfig} />
    </SaveModalContext.Provider>
  );
};

export default AppPrepare;
