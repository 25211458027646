import { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import { useGetWorkerEmployeesQuery } from 'services/1c/cApiService';

import { EMPLOYMENT_TYPE_ENUM } from 'types/employee.types';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

const useInitialAuthRequestsHook = () => {
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();
  const { data: employeesData, isLoading: getEmployeesIsLoading } = useGetWorkerEmployeesQuery(
    undefined,
    {
      skip: !auth?.isAuthenticated,
    },
  );

  useEffect(() => {
    if (isLoading && !auth.isLoading && auth.activeNavigator !== 'signinSilent') {
      setIsLoading(false);
    }
  }, [auth.activeNavigator, auth.isLoading, isLoading]);

  useEffect(() => {
    if (employeesData) {
      if (selectedEmployee) {
        const currentEmployeeDoesNotExist = !employeesData.find(
          (e) => e.id === selectedEmployee.id,
        );
        if (currentEmployeeDoesNotExist) {
          const currentEmployee = employeesData.find(
            (el) => el.employmentType === EMPLOYMENT_TYPE_ENUM.MAIN_WORK,
          );
          currentEmployee
            ? setSelectedEmployee(currentEmployee.id)
            : setSelectedEmployee(employeesData[0]?.id);
        }
      } else {
        const mainWork = employeesData.find(
          (person) => person.employmentType === EMPLOYMENT_TYPE_ENUM.MAIN_WORK,
        );

        if (mainWork) {
          setSelectedEmployee(mainWork.id);
        } else {
          setSelectedEmployee(employeesData[0]?.id);
        }
      }
    }
  }, [employeesData, selectedEmployee, setSelectedEmployee]);

  return { isLoading, getEmployeesIsLoading };
};

export default useInitialAuthRequestsHook;
