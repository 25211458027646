import { useCallback, useMemo } from 'react';

import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { getWorkerEmployeesSelector } from 'services/1c/cApiService';
import { useAppSelector } from 'store';

import { DropdownProps, Flex, Icon, Typography } from 'gazprom-ui-lib';

import { EmployeeType } from 'types/employee.types';

import s from './Header.module.scss';
import Employee from './containers/employee';

const useMenuItems = () => {
  const { data } = useAppSelector(getWorkerEmployeesSelector);
  const [selectedEmployee, setSelectedEmployee] = useFullSelectedEmployee();

  const renderCompany = useCallback(
    (employee: EmployeeType) => {
      const handleSelect = () => setSelectedEmployee(employee.id);
      const isSelected = selectedEmployee?.id === employee.id;

      return {
        key: employee.id,
        label: (
          <button onClick={handleSelect} className={s.button}>
            <Employee employee={employee} isSelected={isSelected} showDivision={false} />
          </button>
        ),
        disabled: isSelected,
      } as DropdownProps['items'][0];
    },
    [selectedEmployee?.id, setSelectedEmployee],
  );

  const items = useMemo(() => {
    const mappedData = data?.map(renderCompany) || [];

    mappedData.push({
      key: 'logout',
      label: (
        <Flex align="center" justify="center" className={s.logoutButton} gap="8">
          <Icon name="logout" />
          <Typography.Text size="14" weight="500" type="primary">
            {t('common_logout')}
          </Typography.Text>
        </Flex>
      ),
    });

    return mappedData;
  }, [data, renderCompany]);

  return items;
};

export default useMenuItems;
