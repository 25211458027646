import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { sortDocumentsFiles } from 'utils/files.utils';

import { useGetBusinessTripArchiveMutation } from 'services/1c/work-tour/workTourApiService';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import FileDownload from 'pages/documents/containers/file-download';
import FileInfo from 'pages/documents/containers/file-info';
import { renderSign } from 'pages/documents/documents.utils';

import { BusinessTripType } from 'types/businessTrip.types';

import s from './Files.module.scss';
import { getFileUrl } from './files.utils';

interface FilesProps {
  id?: string;
  files?: BusinessTripType['files'];
  hideDownload?: boolean;
}

const Files: FC<FilesProps> = (props) => {
  const { id, files, hideDownload } = props;

  const [downloadArchive, { isLoading }] = useGetBusinessTripArchiveMutation();

  if (!files) {
    return <></>;
  }

  if (!files.length) {
    return (
      <Flex gap="4" align="center" className={s.wrapper}>
        <Icon name="time" color="var(--color-character-secondary-45)" size={20} />
        <Typography.Text type="secondary" size="14">
          {t('Приказ находится на стадии формирования')}
        </Typography.Text>
      </Flex>
    );
  }

  const renderFile = (file: BusinessTripType['files'][0]) => {
    const { fileKeyWithStamp, fileName, signs, type, dateDoc, numberDocPrint } = file || {};
    const isSigned = !!signs?.length;
    const isOrderType = type === 'ORDER';
    const fileUrl = id && fileKeyWithStamp ? getFileUrl(id, fileKeyWithStamp) : '';

    if (!fileUrl) {
      return null;
    }

    return (
      <Flex
        key={fileKeyWithStamp}
        justify="space-between"
        align="center"
        gap="12"
        className={cn(s.download, { [s.signed]: isSigned, [s.orderType]: isOrderType })}>
        <Flex align="center" gap="12">
          <Icon name="image" className={s.icon} />
          <Flex vertical gap="4" className={s.fileDescription}>
            <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
              {fileName}
            </Typography.Text>
            <FileInfo dateDoc={dateDoc} numberDocPrint={numberDocPrint} isOrderType={isOrderType} />
            {signs?.map(renderSign)}
          </Flex>
        </Flex>
        {!hideDownload && (
          <FileDownload isSigned={isSigned} fileName={fileName} fileDownloadUrl={fileUrl} />
        )}
      </Flex>
    );
  };

  const handleDownloadFile = () => {
    if (id) {
      downloadArchive({ id, fileName: `Архив командировки` });
    }
  };

  return (
    <Flex vertical className={s.wrapper} gap="8">
      {[...files].sort(sortDocumentsFiles).map(renderFile)}
      <Button
        className={s.downloadArchiveButton}
        loading={isLoading}
        onClick={handleDownloadFile}
        leftIcon="download"
        fullWidth={false}>
        {t('common_download_archive')}
      </Button>
    </Flex>
  );
};

export default Files;
